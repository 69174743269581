



















































import { Component, Vue } from 'vue-property-decorator'
import GroupTargetItem from './GroupTargetItem.vue'
import GroupService from '@/services/GroupService'
import ModalShowProfileWithGroupActivity from '@/components/Modal/ModalShowProfileWithGroupActivity.vue'

@Component({ components: { GroupTargetItem, ModalShowProfileWithGroupActivity } })
export default class GroupTargetList extends Vue {
  public listGroup: any[] = []
  private loading: boolean = true
  public page: number = 1
  public limit: number = this.$store.state.auth.token ? this.$store.state.setting.setting.setting_display.number_item_lists : 6
  private totalData: number = 0
  private selectedUserId: number = -1


  created() {
    this.getGroupByGroupTagTargetId()
    this.limit = this.$store.state.auth.token ? this.$store.state.setting.setting.setting_display.number_item_lists : 6
  }

  showProfileAdminGroup(id: any) {
    this.selectedUserId = id
    this.$bvModal.show('modal-show-profile')
  }

  getGroupByGroupTagTargetId(loadMore?: boolean) {
    const params = {
      limit: this.limit,
      groupTargetId: this.$route.params.groupTargetId
    }
    GroupService.getGroupByGroupTagTargetId(params)
      .then((res) => {
        if (res.status === 200) {
          // this.listGroup = res.data
          const data = res.data.data.map((item: any) => item)
          if (loadMore) {
            this.listGroup = [...this.listGroup, ...data]
          } else {
            this.listGroup = data
          }
          this.totalData = res.data.total
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => (this.loading = false))
  }

  loadMore() {
    const limitDefault = this.$store.state.auth.token ? this.$store.state.setting.setting.setting_display.number_item_lists : 6
    this.page = this.page + 1;
    this.limit = this.limit + limitDefault;
    this.getGroupByGroupTagTargetId()
  }
}
