



























































































































































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
import UserService from '@/services/UserService'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import FormatDate from '@/helpers/FormatDate'
import GroupChatService from '@/services/GroupChatService'

@Component
export default class ModalShowProfileWithGroupActivity extends Vue {
  @Prop({ default: 'modal-show-profile' }) id!: string
  @Prop() userId!: number
  private user_info: any = {}
  private user_personal_info: any = {}
  private avatar_default: any = require('@/assets/images/avatar-default.png')
  private current_date: any = ''
  private last_login_at: any = ''
  private to_be_logged_out: any = ''
  private diff_login: number = 0
  private diff_hours: number = 0
  private diff_day: number = 0
  private date_of_birth: any = ''
  private gender_user: any = ''
  private current_user: number = this.$store.state.userInfo.user.info.id
  private isShow: boolean = false
  private isRole: any = ''
  private receive_chat: number = 0

  @Watch('userId')
  getUserProfile() {
    if (this.userId !== -1) {
      this.getUserPersonalInfoByIdGroupActivity()
      this.getUserProfileByIdGroupActivity()
    } else {
      this.user_info = {}
      this.user_personal_info = {}
    }
  }

  handleShow() {
    this.isShow = true
    setTimeout(() => (this.isShow = false), 500)
  }

  getUserPersonalInfoByIdGroupActivity() {
    UserService.getUserPersonalInfoByIdGroupActivity(this.userId).then(response => {
      if (response.status === 200) {
        this.user_personal_info = response.data
        if (
          this.user_personal_info.birthday_year &&
          this.user_personal_info.birthday_month &&
          this.user_personal_info.birthday_day
        ) {
          this.date_of_birth =
            this.user_personal_info.birthday_year +
            '/' +
            this.user_personal_info.birthday_month +
            '/' +
            this.user_personal_info.birthday_day
        } else {
          this.date_of_birth = ''
        }
      }
    })
  }

  formatDateWithoutDays(date: string) {
    if (date !== '') {
      return FormatDate.formatDateWithoutDays(date)
    } else return null
  }
  getNameGender(gender_user: any) {
    return this.user_personal_info.gender == 1 ? this.$t('setting.change_profile.male') : this.user_personal_info.gender == 0 ? this.$t('setting.change_profile.female') : this.$t('common.select.not_set')
  }

  getUserProfileByIdGroupActivity() {
    UserService.getUserProfileByIdGroupActivity(this.userId)
      .then(response => {
        if (response.status === 200) {
          this.user_info = response.data
          this.to_be_logged_out = response.data.to_be_logged_out
          this.current_date = momentTimeZone
            .tz('Asia/Tokyo')
            .format('YYYY/MM/DD HH:mm:ss')
          this.last_login_at = moment(response.data.last_login_at).format(
            'YYYY/MM/DD HH:mm:ss'
          )
          this.diff_login = moment(this.current_date).diff(
            this.last_login_at,
            'days'
          )
          this.diff_hours = moment(this.current_date).diff(
            this.last_login_at,
            'hours'
          )
          let current_date_without_hours = momentTimeZone
            .tz('Asia/Tokyo')
            .format('YYYY/MM/DD')
          let last_login_without_hours = moment(
            response.data.last_login_at
          ).format('YYYY/MM/DD')
          this.diff_day = moment(current_date_without_hours).diff(
            last_login_without_hours,
            'days'
          )
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleLoginStatus() {
    //online
    if (
      !this.diff_login &&
      !this.diff_day &&
      this.diff_hours === 0 &&
      !this.to_be_logged_out
    )
      return this.$t('modal_show_profile.online')
    //hours_ago
    else if (!this.diff_login && !this.diff_day && this.diff_hours > 0)
      return this.$t('modal_show_profile.hours_ago', { hours: this.diff_hours })
    //less_than_seven diff_day
    else if (!this.diff_login && this.diff_day && this.diff_hours < 24)
      return this.$t('modal_show_profile.less_than_seven', {
        day: this.diff_day
      })
    //less_than_seven diff_login
    else if (this.diff_login > 0 && this.diff_login <= 7)
      return this.$t('modal_show_profile.less_than_seven', {
        day: this.diff_login
      })
    //more_than_seven
    else if (this.diff_login > 7)
      return this.$t('modal_show_profile.more_than_seven')
    //not_login
    else if (!this.diff_login) {
      return this.diff_hours == 0
        ? this.$t('modal_show_profile.logged_out')
        : this.$t('modal_show_profile.not_login')
    }
  }

  hideModal() {
    this.$bvModal.hide(this.id)
  }
}
