































































































































































































// import ModalShowProfileWithGroupActivity from '@/components/Modal/ModalShowProfileWithGroupActivity.vue'
import { EventBus } from '@/event-bus'
import { UserProfile } from '@/models'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ 
  components: {
    // ModalShowProfileWithGroupActivity
  }
})
export default class GroupTargetItem extends Vue {
  @Prop() item!: any
  public imageDefault = require('@/assets/images/image-default.png')
  public imageDefault1 = require('@/assets/images/group/avatar_default_1.png')

  goToGroupTop(id: any) {
    this.$store.state.auth.token ?
    this.$router.push({
      name: 'group-toppage',
      params: { groupId: id }
    }) :
    this.$router.push({
      name: 'login',
      query: {
        redirect: `group/${id}` as any
      }
    })
  }

  goToCompanyDetail(item: any) {
    if (item.inforCompany.status_company == 'stop' || item.inforCompany.status_company == 'leave') {
      return
    } else {
      this.$router.push({
        name: 'company-detail',
        params: { companyId: item.inforCompany.company_id }
      })
    }
  }

  goGroupTop(item: any) {
    if (item.inforCompany.status_company == 'stop' || item.inforCompany.status_company == 'leave') {
      return
    } else {
      this.goToGroupTop(item.id);
    }
  }

  async showMemberProfile(id: any) {
    // this.selectedUserId = id
    
    this.$emit('modal-show', id)
    // console.log(this.$emit('modal-show', id));
    
    // if (this.selectedUserId !== -1) {
      // this.$bvModal.show('modal-show-profile')
    // } else {
    //   this.$bvModal.hide('modal-show-profile')
    // }
    // console.log(id);
    // console.log(this.selectedUserId);
    
  }
}
